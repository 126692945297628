var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-card-list', {
    attrs: {
      "title": "Basic info",
      "item": _vm.item
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Id ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.item._id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item._id) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Iframely Id ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.item.id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item.id) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Title ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.meta.title) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Description ")]), _c('v-list-item-subtitle', {
    staticClass: "white-space-normal"
  }, [_vm._v(_vm._s(_vm.item.meta.description) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Source ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.item.meta.site) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Created in Tutti? ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.isCreatedInTutti) + " ")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('vx-card-list', {
    attrs: {
      "title": "Preview"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.item.html)
    }
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('vx-card-json', {
    attrs: {
      "item": _vm.item
    }
  })], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }