<template>
  <v-row v-if="item">
    <v-col cols="12" sm="6">
      <vx-card-list title="Basic info" :item="item">
        <v-list-item>
          <v-list-item-title> Id </v-list-item-title>
          <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item._id)">
            {{ item._id }}
          </v-list-item-subtitle>
        </v-list-item>

        <v-list-item>
          <v-list-item-title> Iframely Id </v-list-item-title>
          <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item.id)">
            {{ item.id }}
          </v-list-item-subtitle>
        </v-list-item>

        <v-list-item>
          <v-list-item-title> Title </v-list-item-title>
          <v-list-item-subtitle> {{ item.meta.title }} </v-list-item-subtitle>
        </v-list-item>

        <v-list-item>
          <v-list-item-title> Description </v-list-item-title>
          <v-list-item-subtitle class="white-space-normal">{{ item.meta.description }} </v-list-item-subtitle>
        </v-list-item>

        <v-list-item>
          <v-list-item-title> Source </v-list-item-title>
          <v-list-item-subtitle>{{ item.meta.site }} </v-list-item-subtitle>
        </v-list-item>

        <v-list-item>
          <v-list-item-title> Created in Tutti? </v-list-item-title>
          <v-list-item-subtitle> {{ item.isCreatedInTutti }} </v-list-item-subtitle>
        </v-list-item>
      </vx-card-list>
    </v-col>

    <v-col cols="6">
      <vx-card-list title="Preview">
        <div v-html="item.html"></div>
      </vx-card-list>
    </v-col>

    <v-col cols="12">
      <vx-card-json :item="item" />
    </v-col>
  </v-row>
</template>

<script>
import { ContentService } from '@tutti/services';

export default {
  data() {
    return {
      item: null,
      count: {},
    };
  },
  created() {
    this.getContent();
  },
  methods: {
    async getContent() {
      const response = await ContentService.getById(this.$route.params.id);
      if (response) {
        this.item = response.data;
        this.$setTitle(this.item.name);
      }
    },
  },
};
</script>
